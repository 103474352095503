import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  IsNotNullOrWhiteSpace,
  getSaveRequest,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../../constants/Common/Constants";
import { ChecklistTemplateItemRow } from "./ChecklistTemplateItemRow";

export const saveChecklistTemplateItemAPI = (
  checklistTemplateItemRow: ChecklistTemplateItemRow,
  url: string
) => {
  return new Promise<{ entityId: string; objectName: string }>(
    async (resolve) => {
      if (isNotNullAndUndefined(checklistTemplateItemRow)) {
        const URL = IsNotNullOrWhiteSpace(url)
          ? url
          : "ChecklistTemplateItem/Save";
        repositoryActions
          .postDataAndGetResponse(
            URL,
            getSaveRequest(
              checklistTemplateItemRow,
              checklistTemplateItemRow.UID
            ),
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              resolve({
                entityId: response.data.EntityId,
                objectName: response.data.ObjectName,
              });
            } else {
              resolve({ entityId: null, objectName: null });
            }
          })
          .catch((error) => resolve({ entityId: null, objectName: null }));
      } else {
        resolve({ entityId: null, objectName: null });
      }
    }
  );
};

export const getChecklistTemplateItemListByTemplateUID = (
  templateUID?: string
) => {
  return new Promise<ChecklistTemplateItemRow[]>((resolve) => {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    if (isNotNullAndUndefined(templateUID)) {
      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(templateUID);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<ChecklistTemplateItemRow>(
        "ChecklistTemplateUID"
      );
      relatedFilter.Rules.push(filter);
    }

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    return repositoryActions
      .postDataAndGetResponse(
        "ChecklistTemplateItem/List",
        listServiceRequest,
        null,
        ContentType.applicationJson,
        false
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotEmptyArray(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      });
  });
};
